<template>
    <div class="w-screen">
        <div class="banner w-screen flex justify-center items-center">
            <div class="text-center lg:py-20">
                <div class="px-10 animate__slow" id="banner">
                    <p
                        class="
                            font-bold
                            text-3xl
                            lg:text-4xl
                            text-white
                            tracking-wider
                        "
                    >
                        我們的服務
                    </p>
                    <p class="mt-2 text-md text-blue lg:text-2xl">
                        OUR SERVICES
                    </p>
                </div>
                <div class="blue-line h-1 w-full bg-blue mt-4"></div>
            </div>
        </div>
        <div class="max-w-6xl m-auto p-8 lg:p-2">
            <section
                class="
                    container
                    flex
                    items-start
                    justify-center
                    flex-wrap
                    max-w-4xl
                    m-auto
                    p-4
                    lg:p-2
                "
            >
                <div class="service p-6 md:p-2 mt-2 text-center">
                    <img class="px-8 py-2" src="/images/services_01.png" />
                    <p class="mt-2 mb-5 px-2">
                        廣告目標與策略規劃<br />
                        廣告投放操作<br />
                        廣告成效優化<br />
                        客製化報表
                    </p>
                </div>
                <div class="service p-6 md:p-2 mt-2 text-center">
                    <img class="px-8 py-2" src="/images/services_02.png" />
                    <p class="mt-2 mb-5 px-2">
                        廣告數據分析<br />
                        消費者輪廓洞察<br />
                        網站流量分析<br />
                        資訊圖表化
                    </p>
                </div>
                <div class="service p-6 md:p-2 mt-2 text-center">
                    <img class="px-8 py-2" src="/images/services_03.png" />
                    <p class="mt-2 mb-5 px-2">
                        客製化媒體策略<br />
                        媒體建議書<br />
                        預期效益評估
                    </p>
                </div>
                <div class="service p-6 md:p-2 mt-2 text-center">
                    <img class="px-8 py-2" src="/images/services_04.png" />
                    <p class="mt-2 mb-5 px-2">
                        數位廣告顧問<br />
                        企業內部教育訓練
                    </p>
                </div>
                <div class="service p-6 md:p-2 mt-2 text-center">
                    <img class="px-8 py-2" src="/images/services_05.png" />
                    <p class="mt-2 mb-5 px-2">
                        多元網紅媒合<br />
                        提高品牌聲量<br />
                        打造產品口碑
                    </p>
                </div>
            </section>
            <hr id="media" />
            <section class="container">
                <p class="title">媒體通路</p>
                <p class="subtitle">DIGITAL PERFORMANCE</p>
                <div class="flex items-center justify-between flex-wrap mt-4">
                    <img
                        class="media p-2 md:p-5 mt-4"
                        src="/images/media_01.png"
                    />
                    <img
                        class="media p-2 md:p-5 mt-4"
                        src="/images/media_02.png"
                    />
                    <img
                        class="media p-2 md:p-5 mt-4"
                        src="/images/media_03.png"
                    />
                    <img
                        class="media p-2 md:p-5 mt-4"
                        src="/images/media_04.png"
                    />
                    <img
                        class="media p-2 md:p-5 mt-4"
                        src="/images/media_05.png"
                    />
                    <img
                        class="media p-2 md:p-5 mt-4"
                        src="/images/media_06.png"
                    />
                    <img
                        class="media p-2 md:p-5 mt-4"
                        src="/images/media_07.png"
                    />
                    <img
                        class="media p-2 md:p-5 mt-4"
                        src="/images/media_08.png"
                    />
                </div>
            </section>
            <hr id="service-process" />
            <section class="container">
                <p class="title">執行流程</p>
                <p class="subtitle">SERVICE PROCESS</p>
                <div class="w-full">
                    <img
                        class="p-10 max-w-4xl m-auto hidden md:block"
                        src="/images/flow_desk.png"
                    />
                    <img
                        class="md:hidden p-2 m-auto block"
                        src="/images/flow_m.png"
                    />
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import animate from './../animate';

export default {
    mounted() {
        if (window.IntersectionObserver) {
            new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        animate('#banner', 'fadeIn');
                    }
                });
            }).observe(document.getElementById('banner'));
        }
    },
};
</script>

<style lang="scss" scoped>
.banner {
    background: url('/images/banner.jpg') no-repeat center center;
    background-size: cover;
    min-height: 200px;
}
.media {
    width: 25%;
    @media (max-width: 767px) {
        width: 50%;
    }
}
.service {
    width: 30%;
    @media (max-width: 767px) {
        width: 100%;
    }
}
</style>
